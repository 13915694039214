/* Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 4rem;
}

.navbar-logo {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  /* Make the container circular */
  overflow: hidden;
  /* Ensure the image fits within the circular container */
}

.navbar-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensure the image covers the circular container */
}

.navbar-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-right: 50px;
  justify-content: flex-end;
  font-size: 19px;
}

.navbar-links li {
  padding: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  color: #0056b3;
  text-align: center;
  text-decoration: none;
}

.navbar-links li:hover {
  background-color: #0056b3;
  color: white;
}

.menu-toggle {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  color: #0056b3;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 5rem;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    z-index: 999;
    padding: 0;
  }

  .navbar-links.open {
    display: flex;
  }

  .navbar-links.closed {
    display: none;
  }

  .navbar-links li {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .navbar-links li:hover {
    background-color: #0056b3;
  }

  .navbar-links li:hover a {
    color: white;
  }
}