/* General styles */
body {
  font-family: Arial, sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contact {
  margin-top: 60px;
}

.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-section {
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
  background: #f9f9f9;
  flex-wrap: wrap;
}

.contact-form-container {
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  position: relative;
  width: 100%;
}

.testimonial,
.contact-form-container {
  flex: 1;
  box-sizing: border-box;
  padding: 20px;
}

form {
  margin: 20px;
  padding: 2em;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

form:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

form h1 {
  font-size: 1.8rem;
  /* Adjusted font size */
  margin-bottom: 1em;
  color: #004aae;
  font-weight: bold;
  /* Adjusted font weight */
}

form div {
  margin-bottom: 1.2em;
  text-align: left;
}

label {
  margin-bottom: 0.5em;
  color: #333;
  /* Adjusted color */
  display: block;
  font-size: 1rem;
  /* Adjusted font size */
  font-weight: bold;
  /* Adjusted font weight */
}

input,
textarea,
select {
  border: 1px solid #CCCCCC;
  padding: 0.8em;
  /* Adjusted padding */
  font-size: 1rem;
  /* Adjusted font size */
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 0.5em;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #4A90E2;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
  outline: none;
}

button {
  padding: 0.8em 1.5em;
  color: #FFF;
  background-color: #00aaff;
  /* Adjusted background color */
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  /* Adjusted font size */
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 100%;
}

button:hover {
  background-color: #008fcc;
  /* Adjusted hover background color */
  transform: translateY(-3px);
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 9999;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #004aae;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .contact-page {
    flex-direction: column;
  }

  .testimonial,
  .contact-form-container {
    max-width: 100%;
  }

  .contact-form-container {
    order: 1;
  }

  .testimonial-section {
    order: 2;
  }

  form {
    padding: 1.5em;
  }

  form h1 {
    font-size: 1.5rem;
  }
}

.testimonial {
  text-align: center;
  padding: 20px;
}

.testimonial p {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333333;
}

.testimonial h3 {
  font-size: 1rem;
  font-weight: bold;
  color: #333333;
}

.testimonial h4 {
  font-size: 0.9rem;
  color: #777;
}

.profile-picture {
  width: 100px;
  /* Adjust the size as needed */
  height: 100px;
  /* Adjust the size as needed */
  border-radius: 50%;
  margin-bottom: 15px;
  /* Adjust the spacing as needed */
  object-fit: cover;
  /* Ensures the image covers the whole area */
}

.clutch-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.clutch-rating img {
  width: 50px;
  margin-right: 10px;
}

.clutch-rating span {
  font-size: 14px;
  color: #333333;
}

.numbers-section {
  background: #fff;
  padding: 40px 20px;
  text-align: center;
}

.numbers-section h2 {
  font-size: 24px;
  color: #333333;
  margin-bottom: 20px;
}

.numbers {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.number-item {
  margin: 20px;
}

.number-item h3 {
  font-size: 36px;
  color: #4A90E2;
  margin-bottom: 5px;
}

.number-item p {
  font-size: 16px;
  color: #666666;
}

.contact-info-section {
  background: #f9f9f9;
  padding: 40px 20px;
}

.contact-info-section h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
}

.contact-info {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.contact-method {
  text-align: center;
  margin: 20px;
}

.contact-method h3 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #333333;
}

.contact-method p {
  font-size: 16px;
  color: #555;
}

.btn_submit_contact {
  background-color: #004aae;
}


.office-locations {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px;
}

.office {
  background-color: white;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 250px;
}

.office.melbourne {
  border: 2px solid #00aaff;
  background-color: #e0f7ff;
}

.office h3 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #333;
}

.office p {
  font-size: 16px;
  color: #555;
}

.leaflet-control-zoom {
  display: none !important;
}

@media (max-width: 768px) {
  .contact-page {
    flex-direction: column;
  }

  .testimonial,
  .contact-form-container {
    max-width: 100%;
  }

  .contact-form-container {
    order: 1;
  }

  .testimonial-section {
    order: 2;
  }


  .office-locations {
    flex-direction: column;
  }

  .office {
    width: 80%;
  }
}