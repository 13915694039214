.image-container {
    padding: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.image {
    max-width: 100%;
    max-height: 100%;
}

.error-message {
    color: red;
    font-size: 20px;
    text-align: center;
}