.privacy_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 100px;
}

.privacy-policy {
  max-width: 1150px;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: left;
}

.privacy-policy h1,
.privacy-policy h2 {
  color: #333;
}

.privacy-policy p,
.privacy-policy ul,
.privacy-policy ol {
  margin: 0 0 1em 0;
  color: #555;
  line-height: 1.6;
}

.privacy-policy a {
  color: #007BFF;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}