body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.intro-div {
  position: relative;
  background-image: url('../../assests/background-image.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100vh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 6rem;
}

.intro-div::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.588);
  z-index: 1;
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 20px;
  border-radius: 8px;
  max-width: 800px;
  position: relative;
  z-index: 2;
  color: white;
}

.intro-content {
  padding: 20px;
  border-radius: 8px;
  max-width: 100%;
}

.intro h1 {
  font-size: 4rem;
  color: white;
  margin-bottom: 20px;
}

.book_call_btn {
  background-color: #004aae;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 2rem;
  font-size: 1.2rem;
  width: 10rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book_call_btn:hover {
  background-color: #4A90E2;
}

.services {
  padding: 40px 20px;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.services h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #004aae;
}

.services_tagline {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 40px;
}

.services_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;
}

.service {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.service img {
  max-width: 80px;
  margin-bottom: 20px;
}

.service h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #004aae;
}

.service p {
  font-size: 1rem;
  color: #333;
}

.service .fa-icon {
  color: #4A90E2;
  margin-bottom: 20px;
}

.home-section {
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.home-text {
  flex: 1 1 50%;
  max-width: 50%;
  padding-right: 20px;
  text-align: left;
  margin-left: 5rem;
  margin-top: 3rem;
}

.home-text ul li {
  list-style-type: disc;
  font-size: large;
  margin: 20px;
  line-height: 30px;
}

.home-form-container {
  flex: 1 1 50%;
  max-width: 50%;
  margin-top: 0px;
}

.home-form-container label {
  font-weight: bold;
  margin-bottom: 5px;
}

.home-form-container input,
.home-form-container select,
.home-form-container textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
}

.home-info-section {
  background: #f9f9f9;
  padding: 40px 20px;
}

.home-info-section h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
}

.home-info {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.home-method {
  text-align: center;
  margin: 20px;
}

.home-method h3 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #333333;
}

.home-method p {
  font-size: 16px;
  color: #555;
}

.btn_submit_home {
  background-color: #004aae;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner-container {
  width: 100px;
  height: 100px;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #ffaa2b;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: spin 1s linear infinite;
}

.home-form-container form {
  margin: 20px;
  padding: 2em;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-form-container form:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.fixed-bottom-image {
  max-width: 100%;
}

.testimonial-slider {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 3%;
  margin-bottom: 3%;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .services_grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .service {
    margin: 0 auto;
  }

  .intro-div {
    padding: 10px;
  }

  .intro h1 {
    font-size: 2rem;
  }

  .book_call_btn {
    width: 100%;
  }

  .home-section {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
  }

  .home-text,
  .home-form-container {
    max-width: 100%;
  }

  .fixed-bottom-image-container {
    margin-bottom: 10px;
  }

  .call-image-text {
    text-align: left;
  }
}