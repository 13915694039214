code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.footer {
    background-color: #ffffff;
}

.footer-div {
    max-width: 1200px;
    color: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 40px 20px;
    text-align: center;
    margin: 0 auto;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
}

.footer-heading {
    font-size: 1.5rem;
    margin-bottom: 0px;
}

.footer-body {
    font-size: 0.9rem;
    color: grey;
    margin-top: 0px;
    font-weight: bold;
}

.footer-map {
    width: 100%;
    max-width: 400px;
    height: 200px;
    padding: 20px;
}

.footer-logo {
    width: 200px;
    margin-bottom: 10px;
}

.footer-links {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.footer-links a {
    color: #4A90E2;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #4A90E2;
}

.footer-nav {
    display: flex;
    gap: 20px;
}

.footer-nav a {
    color: black;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-nav a:hover {
    color: #4A90E2;
}

.footer-map .leaflet-container {
    height: 100%;
}

.footer-email {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.footer-email-icon {
    color: #4A90E2;
    margin-right: 5px;
}

.footer-copyright {
    font-size: small;
    font-weight: lighter;
    color: grey;
    margin-top: 2rem;
}

@media (max-width: 956px) {
    .footer {
        flex-direction: column;
        align-items: center;
    }

    .footer-content {
        align-items: center;
        margin-bottom: 20px;
    }

    .footer-div {
        justify-content: space-evenly;
        gap: 0px;
    }
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
    }

    .footer-content {
        align-items: center;
        margin-bottom: 20px;
    }
}

@media (max-width: 480px) {
    .footer-map {
        height: 150px;
    }
}